import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <script
  dangerouslySetInnerHTML={{
    __html: `
            
    (function(d, t) {
      var s = d.createElement(t);
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//cdn.viglink.com/api/vglnk.js';
      var r = d.getElementsByTagName(t)[0];
          r.parentNode.insertBefore(s, r);
  }(document, 'script'));
        `,
  }}
/>
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          <p>When you buy through our links, we may earn a commission.</p>
          <p>
            <b>
          &copy; {new Date().getFullYear()}, Heap Labs
          </b>
          </p>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
